// supported regex patterns
const regexRules = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    password: /^(?=.*[A-Z])(?=.*[a-z]).+$/
};

// supported validation rules
export const Validators = {
    required: 'required',
    email: 'email',
    password: 'password',
    minLength: minLength => ({minLength}),
    shouldMatchWithField: shouldMatchWithField => ({shouldMatchWithField}),
    shouldNotContainFields: shouldNotContainField => ({shouldNotContainField})
}

/**
 * Loops through provided 'formModel'
 * each item might contain a 'validations: any[]' property, with possible values from 'Validators' object above.
 * we will map each 'validation rule' to a human readable string.
 *
 * @param values
 * @param formModel
 * @return {{}}
 */
const validate = (values, formModel) => {
    const errors = {};
    let valid = true;

    // loop through each form item
    Object.keys(formModel).forEach(key => {

        // if formItem does not contain 'validations' property
        // skip and assign an empty array
        if (!formModel[key].validations) {
            return errors[key] = [];
        }


        //extract for easier access
        const val = values[key];
        const {label} = formModel[key];

        // loop through 'validations: any[]' property
        // and map the validation rules to strings
        // each validation rule should be mapped to an string
        // if we there is no match, we will return null at the end
        const itemErrors = formModel[key].validations.map(rule => {
            if (rule === Validators.required && !val) {
                return `${label} is required.`;
            }
            if (rule === Validators.email && !regexRules.email.test(val)) {
                return `${label} is not valid.`;
            }
            if (rule === Validators.password && !regexRules.password.test(val)) {
                return `${label} should contain lowercase and uppercase letters.`;
            }
            if (rule.minLength && val.length < rule.minLength) {
                return `${label} should contain ${rule.minLength} or more characters.`
            }
            if (rule.shouldMatchWithField && val !== values[rule.shouldMatchWithField]) {
                return `${label} and ${formModel[rule.shouldMatchWithField].label} don't match.`
            }
            if (rule.shouldNotContainField) {
                let contains = false;
                rule.shouldNotContainField.forEach(filedName => {
                    const testResult = val?.trim().toLowerCase().includes(values[filedName].trim().toLowerCase());
                    if (val && values[filedName] && testResult) {
                        contains = true;
                    }
                })
                if (contains) {
                    const tail = rule.shouldNotContainField.map(fieldName => formModel[fieldName].label).join(' or ');
                    return `${label} should not contain ${tail}.`;
                }

            }
            return null;
        })

        // Add truthy values to errors object
        // errors object should contain 'string[]' only
        errors[key] = [...itemErrors.filter(el => !!el)];
        if (errors[key].length) {
            valid = false;
        }
    });

    return {errors, valid};
}

export default validate;
