import React from 'react';
import './SignUp.css';
import useForm from '../hooks/useForm';
import {Validators} from '../helpers/formValidationHelper';
import axios from '../apis/demoApi';

/**
 * Create Signup page and prepare form
 * @return {JSX.Element}
 * @constructor
 */
const SignUp = () => {

    /**
     * Create formModel
     */
    const formModel = {
        firstName: {
            value: '', label: 'First Name', type: 'text', validations: [Validators.required]
        },
        lastName: {
            value: '', label: 'Last Name', type: 'text', validations: [Validators.required]
        },
        email: {
            value: '', label: 'Email Address', type: 'email', validations: [Validators.required, Validators.email]
        },
        password: {
            value: '', label: 'Password', type: 'password', validations: [
                Validators.required,
                Validators.password,
                Validators.minLength(8),
                Validators.shouldNotContainFields(['firstName', 'lastName'])
            ]
        },
        confirm: {
            value: '', label: 'Confirm', type: 'password', validations: [
                Validators.shouldMatchWithField('password')
            ]
        },
    }

    /**
     * submit function, will be passed to useForm to be called once submit is clicked, and form is valid
     * @return {Promise<void>}
     */
    const onSubmit = async () => {
        try {
            await axios.post('/users', values);
            setTimeout(async () => {
                await axios.get('/users');
            }, 4000)
        } catch (e) {
            console.warn(e);
        }
    }

    const {values, errors, showErrors, onChange, handleSubmit} = useForm(formModel, onSubmit);

    /**
     * Loop through formModel and create each form-control item
     * @return {JSX.Element}
     */
    const renderFormItems = () => {
        return (
            <>
                {Object.keys(values).map(key => {
                    return (
                        <div key={key} className="form-element">
                            <label>{formModel[key].label}
                                <div>
                                    <input
                                        className={showErrors && errors.errors[key].length ? 'error' : ''}
                                        autoComplete="off"
                                        type={formModel[key].type}
                                        name={key}
                                        onChange={onChange}
                                    />
                                </div>
                            </label>
                            {showErrors ? errors.errors[key].map(el => <p key={el}
                                                                          className="error-text">{el}</p>) : null}
                        </div>
                    )
                })}
            </>
        )
    };

    return (
        <div className="signup">
            <div className="form-card">
                <form onSubmit={handleSubmit}>
                    <h2>Sign Up with Email</h2>
                    {renderFormItems()}
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
};

export default SignUp;
