import {useState} from 'react';
import validate from '../helpers/formValidationHelper';

const useForm = (formModel, onSubmit) => {

    // create a new 'key-value' object based of formModel to initiate state
    const initialValues = {};
    Object.keys(formModel).forEach(key => {
        initialValues[key] = formModel[key].value
    })

    // store formValues in the state
    const [values, setValues] = useState(initialValues);
    // store form errors in the state
    const [errors, setErrors] = useState({});
    // by default we don't show errors until a submit attempt is made
    const [showErrors, setShowErrors] = useState(false);

    /**
     * should be called for each field with every change
     * @param event
     */
    const onChange = (event) => {
        // get field name and value from the event object
        const {name, value} = event.target;

        // create new 'values' state object
        const updatedValues = {
            ...values,
            [name]: value
        };

        // update state
        setValues(updatedValues);

        // call validation function
        runValidation(updatedValues);
    }

    /**
     * Calls 'validate' function from validationHelper
     * and stores returned 'errors' object to state
     * @param values
     */
    const runValidation = (values) => {
        setErrors(validate(values, formModel));
    }

    /**
     * To handle form submission,
     * re-validate form and set 'showErrors' to true.
     * //TODO is it a good idea to accept a 'onSubmit' function to execute custom behaviour here?
     * @param event
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        runValidation(values || initialValues);
        setShowErrors(true);

        if (errors.valid) {
            onSubmit();
        }

    }

    return {values, errors, showErrors, onChange, handleSubmit};
}

export default useForm;
