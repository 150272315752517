import React from 'react';
import './App.css'
import Header from './Header';
import SignUp from './SignUp';

const App = () => {

    return (
        <div className="app">
            <Header/>
            <div className="contents">
                <SignUp/>
            </div>
        </div>
    )
}

export default App;
