import React from 'react';
import './Header.css'

const Header = () => {
    return (
        <div data-testid="header" className="header">
            <span data-testid="title" className="title">The Profile</span>
        </div>
    )
}

export default Header;
